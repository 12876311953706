<template>
    <el-dialog
        title="发布演出调整通知"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
    >
        <div class="dialog-content">
            <p>
                在经营平台2.0中，我们优化了演出发布流程，在使用之前，希望您能先了解以下注意事项：
            </p>
            <p>
                首先，我们继续保留了巡演的概念：如果一个艺人需要在不同的场地、不同的时间演出多次，那我们建议您：<span
                    class="color-theme">先创建一场演出，然后将其复制，最后形成多场演出的全国巡演。</span
                >
            </p>
            <p>
                其次，演出新增了场次的概念：如果一个演出，需要在相同的场地、不同的时间段演出多次，那我们建议您：<span class="color-theme">在一场演出下创建多个场次即可，</span>比如音乐节通常连续举办2-4天，就很适合使用这种方法来发布演出（当然您也可以使用巡演的方式来生成多场演出）。
                <!-- <span class="color-danger">多场次功能暂时未开放，我们会尽快开放。</span> -->
            </p>
            <p>
                最后，总结一下新的演出发布流程，您需要：
                <ul>
                    <li>1、完善演出的基本信息：这些信息是演出的共有信息，即使演出存在多个场次，这些信息也都一样，比如海报、场地、主办方等……</li>
                    <li>2、基于基本信息创建场次：大部分演出只有一个场次，如果需要多个场次可以快速复制生成。</li>
                    <li>3、完成演出的创建，如果需要生成巡演，可在演出列表进行相应的快速复制操作。</li>
                    <li class="color-danger">4、对于购票用户提交的退票申请，请及时在秀动APP专业版处理，以免影响后续结算。</li>
					<li class="color-danger">5、当票品售罄，系统会即刻自动开启候补机制。若遇已购票用户退票或有新票源补充，系统将严格依候补顺序为候补用户购票，全力提升票务销售的灵活度与完整性，有效保障活动的票务效益与观众覆盖广度。望您知悉，安心筹备后续票务相关事宜。</li>
                </ul>
            </p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button
                type="primary"
                @click="dialogVisible = false"
                size="small"
                >知道了</el-button
            >
        </span>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: true,
        };
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog{
    min-width: 360px;
    max-width: 700px;
}
.dialog-content {
    // border: 1px $--border-color-base solid;
    // margin: -20px 0;
    // padding: 10px 15px;
    p {
        margin: 10px 0;
        line-height: 24px;
        // text-indent: 20px;
        // span {
        //     color: $--color-theme;
        // }
    }
}
</style>