<template>
    <div class="page-content-box MT10">
        <div class="page-filter-box">
            <el-row :gutter="16">
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-input v-model="params.queryValue" class="input-with-select" size="small">
                        <el-select v-model="params.queryType" slot="prepend" placeholder="请选择">
                            <el-option label="演出名称" :value="1"></el-option>
                            <el-option label="演出ID" :value="2"></el-option>
                        </el-select>
                    </el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-select v-model="params.playStatus" placeholder="请选择结款申请状态" size="small">
                        <el-option value="" label="不限制是否申请结款"></el-option>
                        <el-option :value="1" label="仅看已申请的"></el-option>
                        <el-option :value="0" label="仅看未申请的"></el-option>
                    </el-select>
                </el-col>
				<el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
				    <el-select v-model="params.balanceType" placeholder="请选择投资状态" size="small">
				        <el-option value="" label="不限制秀动是否投资"></el-option>
				        <el-option :value="1" label="仅看已投资的"></el-option>
				        <el-option :value="2" label="仅看未投资的"></el-option>
				    </el-select>
				</el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-select v-model="params.receivablesStatus" placeholder="请选择结款状态" size="small">
                        <el-option value="" label="不限制结款状态"></el-option>
                        <el-option :value="3" label="仅看不可结款的"></el-option>
                        <el-option :value="1" label="仅看未结款的"></el-option>
                        <el-option :value="2" label="仅看已结款的"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="16" :lg="12" :xl="8">
                    <div class="clearfix timeType">
                        <el-input class="input-with-select fl" size="small">
                            <el-select v-model="params.queryTimeType" slot="prepend" placeholder="请选择">
                                <el-option label="演出结束时间" :value="1"></el-option>
                                <el-option label="结款申请时间" :value="2"></el-option>
                                <el-option label="打款时间" :value="3"></el-option>
                            </el-select>
                        </el-input>
                        <el-date-picker
                            v-model="datetimerange"
                            type="datetimerange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            class="fl"
                            size="small"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']"
                            unlink-panels
                            >
                        </el-date-picker>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
                    <el-button type="primary" @click="search" size="small">搜索</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="page-list-box">
            <el-table
                :data="dataList"
                style="width: 100%"
                v-loading="listLoading"
                size="small"
				border
                header-row-class-name="page-list-table"
            >
               <!-- <el-table-column prop="id" label="演出ID"></el-table-column>
                <el-table-column prop="title" label="演出名称" min-width="220" show-overflow-tooltip></el-table-column> -->
				<el-table-column label="业务状态" width="150">
					<template slot-scope="scope">
						<p>申请结款：{{scope.row.playPriceStatus == 1 ? '已申请' : '未申请'}}</p>
						<p>结款状态：{{scope.row.playStatus == 1 ? '未结款' : scope.row.playStatus == 2 ? '已结款' : '不可结款'}}</p>
					</template>
				</el-table-column>
				<el-table-column label="演出" min-width="300" show-overflow-tooltip>
					<template slot-scope="scope">
						<p>演出ID：{{scope.row.id}}</p>
						<p>演出名称：{{scope.row.title}}</p>
					</template>
				</el-table-column>	
                <el-table-column prop="endTime" label="演出结束时间" min-width="145"></el-table-column>
                <el-table-column prop="status" label="演出状态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status == 1" type="warning" size="mini">待审核</el-tag>
                        <el-tag v-if="scope.row.status == 2" type="success" size="mini">销售中</el-tag>
                        <el-tag v-if="scope.row.status == 3" type="info" size="mini">已结束</el-tag>
                        <el-tag v-if="scope.row.status == 4" type="info" size="mini">已取消</el-tag>
                        <el-tag v-if="scope.row.status == 5" type="danger" size="mini">未通过</el-tag>
                    </template>
                </el-table-column>
				<el-table-column  label="销售" min-width="150">
					<template slot-scope="scope">
						<p>销售票数：{{scope.row.saleCount}}</p>
						<p>销售总额：{{scope.row.saleAmount}}</p>
					</template>
				</el-table-column>
				<el-table-column  label="实际收入" min-width="150">
					<template slot-scope="scope">
						<p>实际收入：{{scope.row.incomeStr}}</p>
						<p>承担优惠：{{scope.row.busDiscountAmountStr}}</p>
					</template>
				</el-table-column>
				<el-table-column  label="退票" min-width="150">
					<template slot-scope="scope">
						<p>退票总数：{{scope.row.refundCount}}</p>
						<p>退票手续费：{{scope.row.refundFeeStr}}</p>
					</template>
				</el-table-column>
				<el-table-column  label="秀动服务费" min-width="200">
					<template slot-scope="scope">
						<p>收费方式：
							<span v-if="scope.row.serviceFeeType == 0">不收取</span>
							<span v-if="scope.row.serviceFeeType == 1">固定金额</span>
							<span v-if="scope.row.serviceFeeType == 2">按比例收取({{scope.row.serviceFeeRate}}%)</span>
						</p>
						<p>服务费：{{scope.row.serviceFeeStr}}</p>
                        <p v-if="scope.row.technicalServiceFeeStr">额外技术服务费：{{ scope.row.technicalServiceFeeStr }}</p>
					</template>
				</el-table-column>
				<el-table-column  label="大麦服务费" min-width="150">
					<template slot-scope="scope">
						<p>收费比例：<span>{{scope.row.damaiServiceFeeRatioStr}}</span></p>
						<p>服务费：{{scope.row.damaiServiceFeeStr}}</p>
					</template>
				</el-table-column>
				
               <!-- <el-table-column prop="saleCount" label="销售票数"></el-table-column>
                <el-table-column prop="saleAmount" label="销售金额" min-width="100"></el-table-column> -->
				<!-- <el-table-column prop="refundFeeStr" label="退票手续费" min-width="100"></el-table-column> -->
				
               <!-- <el-table-column prop="serviceFeeType" label="收费方式" min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.serviceFeeType == 0">不收取</span>
                        <span v-if="scope.row.serviceFeeType == 1">固定金额</span>
                        <span v-if="scope.row.serviceFeeType == 2">按比例收取({{scope.row.serviceFeeRate}}%)</span>
                    </template>
                </el-table-column>
                <el-table-column prop="serviceFeeStr" label="服务费用"></el-table-column> -->
				<el-table-column prop="byteMiniServiceFeeStr" label="抖音服务费" min-width="100">
                    <template slot-scope="scope">
                        <p>服务费：{{scope.row.byteMiniServiceFeeStr}}</p>
                        <p v-if="scope.row.byteMiniCpsFeeView">CPS佣金：{{scope.row.byteMiniCpsFeeView}}</p>
                    </template>
                </el-table-column>
				<el-table-column prop="redBookletServiceFeeStr" label="小红书服务费" min-width="100">
                    <template slot-scope="scope">
                        <p>服务费：{{scope.row.redBookletServiceFeeStr}}</p>
                        <p v-if="scope.row.redBookletCpsFeeView">CPS佣金：{{scope.row.redBookletCpsFeeView}}</p>
                    </template>
                </el-table-column>
                <el-table-column label="网易云服务费用" min-width="120">
                    <template slot-scope="scope">
                        <p>收费比例：<span>{{scope.row.neteaseCloudServiceFeeRatioStr}}</span></p>
						<p>服务费：{{scope.row.neteaseCloudServiceFeeStr}}</p>
                    </template>
                </el-table-column>
                <el-table-column label="QQ音乐服务费用" min-width="120">
                    <template slot-scope="scope">
                        <p>收费比例：<span>{{scope.row.qqServiceFeeRatioStr}}</span></p>
						<p>服务费：{{scope.row.qqServiceFeeStr}}</p>
                    </template>
                </el-table-column>
                <el-table-column label="实际打款金额" min-width="100">
					<template slot-scope="scope">
						<span class="color-danger">{{scope.row.playPriceTicketStr}}</span>
					</template>
				</el-table-column>
                <!-- <el-table-column prop="playPriceStatus" label="申请结款">
                    <template slot-scope="scope">
                        {{scope.row.playPriceStatus == 1 ? '已申请' : '未申请'}}
                    </template>
                </el-table-column>
                <el-table-column prop="playStatus" label="结款状态">
                    <template slot-scope="scope">
                        {{scope.row.playStatus == 1 ? '未结款' : scope.row.playStatus == 2 ? '已结款' : '不可结款'}}
                    </template>
                </el-table-column> -->
                <!-- <el-table-column prop="createTime" label="申请结款时间" min-width="145"></el-table-column>
                <el-table-column prop="playTime" label="打款时间" min-width="145"></el-table-column> -->
				<el-table-column prop="balanceType" label="秀动投资" min-width="100">
					<template slot-scope="scope">					    
					    <span v-if="scope.row.balanceType == 1">已投资</span>
					    <span v-if="scope.row.balanceType == 2">未投资</span>
					</template>
				</el-table-column>
				<!--
				playStatus	结款状态 1：待打款，2：已打款，3：拒绝打款
				
				playPriceStatus	申请结款状态 1:已申请，0：未申请
				-->
                <el-table-column fixed="right" label="操作" min-width="150">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.playPriceStatus == 0 && scope.row.playStatus == 1" type="text" size="small" @click="showDetail(scope.row)">申请结款</el-button>					
						<el-button v-if="scope.row.playPriceStatus == 0 && scope.row.playStatus == 2" type="text" size="small" @click="showDetail(scope.row)" >结款明细</el-button>
						<el-button v-if="scope.row.playPriceStatus == 1 && (scope.row.playStatus == 1 || scope.row.playStatus == 2 )" type="text" size="small" @click="showDetail(scope.row)" >结款明细</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="data" class="page-pagination-box">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="getDataList"
                :current-page.sync="params.pageNo"
                :page-sizes="[15, 30, 50]"
                :page-size.sync="params.pageSize"
                layout="sizes, prev, pager, next"
                :total="data.totalCount || 0">
            </el-pagination>
        </div>
        <ViewDetail ref="viewDetail" @onUpdate="onUpdateData"></ViewDetail>
    </div>
</template>

<script>
import ViewDetail from './viewDetail';
export default {
    components:{ViewDetail},
    data(){
        return{
            data: null,
            dataList: [],
            params: {
                endTime: "",
                pageNo: 1,
                pageSize: 15,
                playStatus: "",
                queryTimeType: 1,
                queryType: 1,
                queryValue: "",
                receivablesStatus: "",
                startTime: "",
				balanceType:''
            },
            listLoading: true,
            datetimerange:[],
        }
    },
    mounted(){
        this.getDataList();
    },
    methods:{
        onUpdateData(){
            this.$parent.$refs.statistics.getData();
            this.getDataList();
        },
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/activity/receivables/getActivityAudiPage",
                method: "POST",
                data: {
                    ...this.params,
                    startTime:this.datetimerange && this.datetimerange[0] || '',
                    endTime:this.datetimerange && this.datetimerange[1] || ''
                },
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
						
                        this.dataList = result.result || [];
						
                    } else {
                        this.$message.error(msg || "获取申请列表信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取申请列表信息错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pageNo = 1;
            this.getDataList();
        },
        showDetail(data){
			if( data.balanceType != 1 ){
				this.$refs.viewDetail.show(data);
			}else{
				this.$message.warning('秀动参与投资的演出暂不支持申请结算！');
			}
            
        }
    }
}
</script>