<template>
    <div :key="reFresh">
        <template v-for="(item,index) in formData">
            <el-card v-show="item.status != -1" :key="index" shadow="never">
                <div slot="header" class="clearfix">
                    <h2 class="fl">
                        场次{{ index + 1 - formData.filter((v,i) => index > i && v.status == -1).length}}  
                        <small v-if="item.status === 0"><span class="color-danger el-icon-warning"> 当前场次已取消</span></small> 
                    </h2>
                    <el-button v-if="formData.filter(v => v.status != -1).length > 1 && (!item.id && editType == 1 || editType != 1)" icon="el-icon-delete" type="text" class="fr" size="mini" @click="delOs(index)">删除</el-button>
                </div>

                <el-row :gutter="24">
                    <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item :prop="`sessions[${index}].startTime`" :rules="[{required:  item.status != -1 ? true : false, message: '请选择演出开始时间'}]">
                            <span slot="label">演出开始时间: </span>
                            <el-date-picker v-model="item.startTime" type="datetime" placeholder="演出开始时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm" default-time="20:30:00" size="medium" :disabled="item.status === 0" @change="formatName()"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item :prop="`sessions[${index}].endTime`" :rules="[{required:  item.status != -1 ? true : false, message: '请选择演出结束时间'}]">
                            <span slot="label">演出结束时间: </span>
                            <el-date-picker v-model="item.endTime" type="datetime" placeholder="演出结束时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm" default-time="22:00:00" size="medium" :disabled="item.status === 0"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
				<el-row :gutter="24">
				    <!-- <el-col :xs="24" :sm="12" :xl="8">
				        <el-form-item :prop="`sessions[${index}].canRefundTime`">
				            <span slot="label">申请退票截止时间: <small><div class="color-danger">如果该场次允许用户申请退票，则用户只能在该时间以前提起申请</div></small></span>
				            <el-date-picker v-model="item.canRefundTime" type="datetime" placeholder="申请退票截止时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm" default-time="20:30:00" size="medium" :disabled="item.status === 0" ></el-date-picker>
				        </el-form-item>
				    </el-col> -->
                    <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item :prop="`sessions[${index}].sessionName`">
                            <span slot="label">场次名称: <small><div class="color-danger">建议使用开演日期+时间作为场次名称，增加用户辨识度</div></small></span>
                            <el-input class="sessionName" v-model.trim="item.sessionName" :placeholder="item.sessionNamePlaceholder" size="medium" maxlength="40" :disabled="item.status === 0"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item :prop="`sessions[${index}].performers`" :rules="[{required:  item.status != -1 && !item.customPerformer ? true : false, message: '请填写艺人阵容'}]">
                            <span slot="label">艺人阵容: <small>没找到相关艺人，点击<el-button type="text" size="mini" :disabled="item.status === 0" @click="customPerformerAction(index,1)">创建自定义艺人</el-button></small></span>
                            <!-- <el-input v-model="item.customPerformer" placeholder="请输入艺人阵容" size="medium" :disabled="item.status === 0"></el-input> -->
                            <el-select 
                                v-model="item.performers" 
                                filterable 
                                remote
                                multiple
                                placeholder="请选择艺人"
                                :remote-method="searchArtist"
                                :loading="userLoading"
                                size="medium"
								@focus="onFocus(index)"
								@change="updateUser(index)"
                                value-key="id"
                                >
                                <el-option
                                    v-for="a in item.artistList"
                                    :key="a.id"
                                    :label="a.name"
                                    :value="a">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <div v-if="item.customPerformer">自定义艺人: <span class="color-danger">{{item.customPerformer}}</span></div>
                    </el-col>
                </el-row>
				<el-row :gutter="24">
					<el-col :xs="24" :sm="12" :xl="8">
					    <el-form-item :prop="`sessions[${index}].guests`">
					        <span slot="label">嘉宾阵容: <small>没找到相关嘉宾，点击<el-button type="text" size="mini" :disabled="item.status === 0" @click="customPerformerAction(index,2)">创建自定义嘉宾</el-button></small></span>
					        <!-- <el-input v-model="item.customPerformer" placeholder="请输入艺人阵容" size="medium" :disabled="item.status === 0"></el-input> -->
					        <el-select 
					            v-model="item.guests" 
					            filterable 
					            remote
					            multiple
					            placeholder="请选择嘉宾"
					            :remote-method="searchGuests"
					            :loading="userLoading"
					            size="medium"
								@focus="onFocus(index)"								
					            value-key="id"
					            >
					            <el-option
					                v-for="a in item.guestsList"
					                :key="a.id"
					                :label="a.name"
					                :value="a">
					            </el-option>
					        </el-select>
					    </el-form-item>
					    <div v-if="item.customGuest">自定义嘉宾: <span class="color-danger">{{item.customGuest}}</span></div>
					</el-col>
				</el-row>
                <el-row :gutter="24" v-if="item.performers && item.performers.length >0">
                    <el-col :xs="24" :xl="16">
                        <el-form-item >                            
							<span slot="label">出场顺序及表演时间段: 
								<small class="MR20"><div class="color-danger">设置后会在app推送通知，建议有多个艺人出演时设置</div></small>
								<el-button type="text" size="mini" @click="timeShow(index)" icon="el-icon-plus">创建艺人出场时段</el-button>
								<el-button type="text" size="mini" @click="clearTime(index)" icon="el-icon-close" v-if="item.setTime">清除艺人出场时段</el-button>
							</span>
							<div class="optionsListMain" v-if="item.setTime">
								<div v-for="(items, indexs) in item.performers" class="optionsList MT10" :key="indexs">
                                    <el-tag type="info" size="medium">{{items.name}}</el-tag>
                                    <el-date-picker v-model="items.datetimerangeUser" @change="changeTime(index)" type="datetimerange" range-separator="至"
                                        value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
                                        start-placeholder="开始时间" end-placeholder="结束时间" unlink-panels size="small">
                                    </el-date-picker>
								</div>
							</div>
                        </el-form-item>
                    </el-col>
                    
                </el-row>
                <el-row :gutter="24">
                    <el-col :xs="24" :xl="16">
                        <el-form-item :prop="`sessions[${index}].ticketPrices`" :rules="[{required:  item.status != -1 ? true : false, message: '请添加票品'}]" :show-message="item.ticketPrices && item.ticketPrices.length < 1 || !item.ticketPrices ? true : false">
                            <span slot="label">票品列表: 
                                <el-button type="text" icon="el-icon-circle-plus-outline" @click="addTickPrices(index)" :disabled="item.status === 0">新增票品</el-button>
                            </span>
                            <el-table
                                :data="item.ticketPrices && item.ticketPrices.filter( v => v.status != -1)"
                                style="width: 100%"
                                size="small"
                                border
                                row-key="ticketName"
                                :class="`sortable-${index}`"
                                row-class-name="ticketList"
                                header-row-class-name="page-list-table"
                            >
                                <el-table-column label="排序" width="48" class-name="sort-handle">
                                    <span class="el-icon-sort"></span>
                                </el-table-column>
                                <el-table-column prop="ticketName" label="名称" min-width="100" show-overflow-tooltip>
                                    
                                </el-table-column>
                                <el-table-column label="类型">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.groupId == 1">普通门票</span>
                                        <span v-else-if="scope.row.groupId == 2">车票</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="memberNum" label="可进场人数" min-width="100">
                                    <template slot-scope="scope">{{memberNum[scope.row.memberNum]}}</template>
                                </el-table-column>
                                <el-table-column prop="sellPrice" label="售价" min-width="90">
                                    <template slot-scope="scope">
                                        {{parseFloat(scope.row.sellPrice).toFixed(2)}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="originalPrice" label="原价" min-width="90">
                                    <template slot-scope="scope">
                                        {{parseFloat(scope.row.originalPrice).toFixed(2)}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="accountLimitNum" label="账号限购">
                                    <template slot-scope="scope">
                                        {{scope.row.accountLimitNum == 0 ? '不限购' : scope.row.accountLimitNum}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="totalNum" label="总库存(票)"></el-table-column>

                                <template v-if="editType == 1">
                                    <el-table-column label="销售状态">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.status == 0" class="color-success">正常销售</span>
                                            <span v-if="scope.row.status == 2" class="color-warning">优惠码销售</span>
                                            <span v-if="scope.row.status == 1" class="color-info">暂停销售</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sellNum" label="已销售(票)">
                                        <template slot-scope="scope">
                                            {{scope.row.sellNum || 0}}
                                        </template>
                                    </el-table-column>    
                                    <el-table-column prop="occupyNum" label="待支付(票)">
                                        <template slot-scope="scope">
                                            {{scope.row.occupyNum || 0}}
                                        </template>
                                    </el-table-column>   
                                    <el-table-column label="剩余(票)">
                                        <template slot-scope="scope">
                                            {{scope.row.totalNum - (scope.row.sellNum || 0) - (scope.row.occupyNum || 0)}}
                                        </template>
                                    </el-table-column>   
                                    <el-table-column prop="refundNum" label="已退(票)">
                                        <template slot-scope="scope">
                                            {{scope.row.refundNum || 0}}
                                        </template>
                                    </el-table-column>   
                                </template> 

                                <el-table-column label="销售时间" min-width="170">
                                    <template slot-scope="scope">
                                        <p v-if="scope.row.startTime">起:{{scope.row.startTime}}</p>
                                        <p v-if="scope.row.endTime">止:{{scope.row.endTime}}</p>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column label="验票时间" min-width="160">
                                    <template slot-scope="scope">
                                        <p v-if="scope.row.validStartTime">起:{{scope.row.validStartTime}}</p>
                                        <p v-if="scope.row.validEndTime">止:{{scope.row.validEndTime}}</p>
                                    </template>
                                </el-table-column> -->
                                <el-table-column prop="instruction" label="票品说明" min-width="100" show-overflow-tooltip>
                                    
                                </el-table-column>
                                <el-table-column v-if="item.status === 1" label="操作" width="110" fixed="right">
                                    <template slot-scope="scope">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="editTickPrices(scope.row,index,scope.$index)"
                                            >编辑</el-button
                                        >
                                        <el-button
                                            v-if="editType == 1 && scope.row.id && scope.row.isEditStore == 1"
                                            type="text"
                                            size="small"
                                            @click="editTickStock(scope.row,index,scope.$index)"
                                            >调整库存</el-button
                                        >
                                        <el-button
                                            v-if="editType == 1 && !scope.row.id || editType != 1"
                                            type="text"
                                            size="small"
                                            @click="delTickPrices(scope.row,index,scope.$index)"
                                            >删除</el-button
                                        >
                                    </template>
                                </el-table-column>
                            </el-table>
                            <small v-if="item.status === 1"><i class="el-icon-info"></i> 按住鼠标可拖动排序</small>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </template>

        <!-- 票品编辑     -->
        <ticket-price-edit 
            ref="ticketPriceEdit" 
            :ticketPrices="formData[osIndex].ticketPrices.filter(v => v.status !== -1)" 
            :itemData="ticketPricesItem" 
			:hallId="hallId"
            :index="parseInt(ticketPricesItemIndex)" 
            :editType="editType"
			:canEdit="canEdit"
			:startTime="formData[osIndex].startTime"  
            :endTime="formData[osIndex].endTime"  
            :activityId="formData[osIndex].activityId"
			:formData="formData[osIndex]"
			:activityData="activityData"
            @onUpdate="onUpdateTicketPrice">
        </ticket-price-edit>

        <!-- 票品库存编辑 -->
        <ticket-stock-edit
            ref="ticketStockEdit"
            :itemData.sync="ticketPricesItem"
            :index="parseInt(ticketPricesItemIndex)"
            @onUpdate="onUpdateTicketPrice">
        </ticket-stock-edit>

        <!-- 自定义艺人 -->
        <el-dialog
            title="自定义艺人"
            :visible.sync="customPerformerVisible"
            width="350px"
            center>
            <div class="dialog-content">
                <small class="color-info">多个乐队或音乐人请用逗号分隔</small>
                <div class="MT20">
                    <el-input v-model="customPerformer" type="textarea" autosize placeholder="请输入自定义艺人"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="customPerformerVisible = false">取 消</el-button>
                <el-button type="primary" @click="addCustomPerformer">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import TicketPriceEdit from './ticketPricesEdit';
import TicketStockEdit from './ticketStockEdit';
import Sortable from "sortablejs";
import {fetchUser} from '@/assets/js/commonApi';
export default {
    props:{
       formData: {
            type: Array,
            default: () => []
        }, 
        //编辑类型：1、演出详情（编辑）2、演出发布
        editType: {
            type: [Number,String],
            default: 1
        },
		hallId:{
			type:[Number,String],
			default: ''
		},
		copy: {
            type: [Number,String],
            default:''
        },
		activityData:{
			type: Object,
			default: null,
		}
    },
    components:{TicketPriceEdit,TicketStockEdit},
    data(){
        return{
            osIndex:0,
            ticketPricesItem: null,
            ticketPricesItemIndex:0,
            reFresh:false,
            userLoading: false,
            customPerformer: '',
            customPerformerVisible: false,
			canEdit:''
        }
    },
    computed:{
        memberNum(){
            return{
                1: '单人',
                2: '双人',
                3: '三人',
                4: '四人',
                5: '五人',
                6: '六人',
                7: '七人',
                8: '八人',
                9: '九人',
                10: '十人'
            }
        }
    },
    watch:{
		
       'formData.length'(v){
		   
            if(v && v > 0){
                this.reFresh = !this.reFresh;
                // this.$forceUpdate();
                this.$nextTick(() => {
                    this.updateTickPricesSort();
                    this.drag(); 
                    this.formatName();
                })
            }
        }
    },
    mounted(){
    //    this.updateTickPricesSort();
       this.drag(); 
       this.formatName();
    },
    methods:{		
		updateUser(index){			
			this.osIndex = index;
			this.formData[this.osIndex].performers.map(items => {
				var datetimerangeUser = ["",""];
				if(items.startTime && items.endTime){
					datetimerangeUser = [items.startTime, items.endTime];
				}
				this.$set(items, 'datetimerangeUser', datetimerangeUser);
			})
		},
		timeShow(index){
			this.osIndex = index;
			this.formData[this.osIndex].setTime = true
			this.updateUser(this.osIndex)
		},
		changeTime(index){
			this.osIndex = index;
			this.formData[this.osIndex].performers.forEach( item => {
				if(item.datetimerangeUser == null){
					item.startTime = "";
					item.endTime = "";
					item.datetimerangeUser = ["",""];
				}else{
					item.startTime = item.datetimerangeUser[0];
					item.endTime = item.datetimerangeUser[1];
				}
			})
			
		},
		clearTime(index){
			// console.log(this.formData[this.osIndex].performers)
			this.osIndex = index;
			this.formData[this.osIndex].performers.forEach( item => {
				item.datetimerangeUser = ["",""]
				item.startTime = "";
				item.endTime = "";
				
			})
		},
        //删除场次
        delOs(index){
            this.$confirm('确定要删除当前场次吗?删除后数据不可恢复', '提示', {
                type: 'warning',
                center: true,
                closeOnClickModal: false
            }).then(() => {
                let _sessions = [...this.formData];
                if( _sessions[index].id ){
                    _sessions[index].status = -1;
                }else{
                    _sessions = [...this.formData.filter( (v,i) => i !== index )];
                }
                this.osIndex = 0;
                this.$emit('update:formData', [..._sessions]);
            }).catch(() => {})
        },
        //新增票品事件
        addTickPrices(index){
			this.canEdit = 1
            this.osIndex = index;
            this.ticketPricesItem = null;
            this.ticketPricesItemIndex = this.formData[index].ticketPrices.length;
            this.$refs.ticketPriceEdit.show();
        },
        //编辑票品事件
        editTickPrices(item,osIndex,index){				
			// console.log(this.editType)
			// console.log(item.id)
			// console.log(this.copy)
			
			if(this.editType == 1 && !item.id || this.editType != 1 ){
				this.canEdit = 1
			}else{
				this.canEdit = 0
			}
			
			// if( !item.id ){//没有id就是新增，可以编辑票品形状 1是可以编辑，0是不可编辑 1、演出详情（编辑）2、演出发布
			// 	this.canEdit = 1
			// }else if( item.id && !this.copy && this.editType == 1  ){
			// 	this.canEdit = 0
			// }else if( item.id && this.copy == 1){ //有id，是复制演出过来的，也可以编辑
			// 	this.canEdit = 1
			// }else if( item.id && this.editType == 2 && !this.copy ){
			// 	this.canEdit = 1
			// }
            this.osIndex = osIndex;
            this.ticketPricesItem = item;
            this.ticketPricesItemIndex = index;
            this.$refs.ticketPriceEdit.show();
        },
        //删除票品事件
        delTickPrices(item,osIndex,index){
            this.osIndex = osIndex;
            this.$confirm('确定要删除当前票品吗?删除后数据不可恢复', '提示', {
                type: 'warning',
                center: true,
                closeOnClickModal: false
            }).then(() => {
                if(item.id){
                    this.$set(
                        this.formData[this.osIndex].ticketPrices,item.sort,
                        {...this.formData[this.osIndex].ticketPrices[item.sort],status:-1}
                    );
                }else{
                    this.formData[this.osIndex].ticketPrices = [...this.formData[this.osIndex].ticketPrices.filter( (v,i) => i !== item.sort )];
                }
                
                this.updateTickPricesSort();
            }).catch(() => {})
        },
        //更新票品排序
        updateTickPricesSort(){
            this.formData[this.osIndex].ticketPrices.map( (item,index) => {
                // item.sort = index;
                this.$set(this.formData[this.osIndex].ticketPrices[index],'sort',index);
            })
        },
        //更新票品列表
        onUpdateTicketPrice(data, callback){
            const obj = data;
            const _status = this.formData[this.osIndex].ticketPrices.filter( v => v.status == 1 );
            if(_status.length >= this.formData[this.osIndex].ticketPrices.length - 1 && obj.status == 1){
                this.$message.error('请至少保留一个票品正常销售');
                return;
            }
            this.updateTickPricesSort();
            this.$set(this.formData[this.osIndex].ticketPrices,obj.sort,{...obj});
            this.drag();
            callback && callback();
        },
        //调整库存
        editTickStock(item,osIndex,index){
            this.osIndex = osIndex;
            this.ticketPricesItem = item;
            this.ticketPricesItemIndex = index;
            this.$refs.ticketStockEdit.show();
        },
        //票品拖拽排序
        drag(){
            this.$nextTick(() => {
                this.formData.forEach((item,index) => {
                    const el = document.querySelectorAll('.sortable-'+index+'> .el-table__body-wrapper > table > tbody')[0];
                    Sortable.create(el, {
                        disabled: this.formData[index].status === 0 ? true : false, // 拖拽不可用? false 启用
                        ghostClass: 'sortable-ghost', //拖拽样式
                        animation: 150, // 拖拽延时，效果更好看
                        handle: ".sort-handle",
                        onEnd: (e) => { // 拖拽结束时的触发
                            let arr = item.ticketPrices; // 获取表数据
                            arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理，获取最新的表格数据
                            this.$nextTick(function () {
                                item.ticketPrices = arr;
                                this.osIndex = index;
                                this.updateTickPricesSort();
                                // console.log(item.ticketPrices)
                            })
                        },
                    });
                })
            })
        },
        //艺人搜索
        onFocus(index){
            this.osIndex = index;
        },
       
		searchArtist(query) {
			this.searchUser(query, 2, 'artistList');
		},
		searchGuests(query) {
			this.searchUser(query, 2, 'guestsList');
		},
        // 搜索用户
        async searchUser(query,userType,name ){
            if(query !== ""){
                this.userLoading = true;
                const res = await fetchUser({
                    queryValue:query,
                    userType:userType,
                }).finally(() => {
                    this.userLoading = false;
                });
                
                const { state, result } = res.data;
                
                if (state == 1 && result) {
					
					if( name == 'artistList' ){
						const arry = [...this.formData[this.osIndex].performers];
						this.formData[this.osIndex].artistList = []
						result.map(v => {
							if(!arry.find(item => item.id == v.id)){
								arry.push(v);
							}
						})
						this.formData[this.osIndex].artistList = [...arry];							
					}
					if( name == 'guestsList' ){
						const arry2 = [...this.formData[this.osIndex].guests];
						this.formData[this.osIndex].guestsList = []
						result.map(v => {
							if(!arry2.find(item => item.id == v.id)){
								arry2.push(v);
							}
						})
						this.formData[this.osIndex].guestsList = [...arry2];							
					}
                }
            }
        },
        //自定义艺人
        customPerformerAction(index, artistType){
            this.osIndex = index;
            //this.customPerformer = this.formData[index].customPerformer;
            this.customPerformerVisible = true;
			this.artistType = artistType;
			
        },
        addCustomPerformer(){			
			if (this.artistType === 1) {
				this.formData[this.osIndex].customPerformer = this.customPerformer;
				
			} else {
				this.formData[this.osIndex].customGuest = this.customPerformer;
				
			}
			this.customPerformer = ''
			this.customPerformerVisible = false;
        },

        //场地名称自动生成
        formatName(){
            this.$nextTick(() => {
                this.formData.map(v => {
                    if( v.startTime ){
                        const _time = this.$utils.formatDate(v.startTime, 'yyyy-MM-dd HH:mm');
                        const _name1 = _time.split(' ')[0];
                        const _name2 = this.$utils.getWeekDate(_time);
                        const _name3 = _time.split(' ')[1];

                        v.sessionNamePlaceholder = _name1 + ' '+ _name2 + ' ' + _name3;
                    }else{
                        v.sessionNamePlaceholder = "请输入场次名称"
                    }
                })
                this.$emit("update:formData",[...this.formData]);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.el-card {
    margin-bottom: 10px;
    border:0;
    ::v-deep .el-card__header{
        h2{
            line-height: 28px;
        }
    }
}

.sessionName{
    ::v-deep input::placeholder{
        color: #666;
        opacity: 1;
    }
}
// .w300{
// 	width: 300px;
// 	margin-right: 15px;
// }
// ::v-deep .el-drawer .el-form .el-select, .el-drawer .el-form .el-date-editor, .el-drawer .el-form .el-cascader, .page-main .el-form .el-select, .page-main .el-form .el-date-editor, .page-main .el-form .el-cascader{
//    width: 400px;
//    margin-right: 15px;
// }
.optionsList{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .el-tag{
        margin-right: 10px;
    }
    .el-date-editor{
        flex: 1;
        min-width: 200px;
    }
}
</style>