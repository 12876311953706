<template>
    <div>
        <div class="page-top-box">
            <div class="page-title-box">
                <div class="go-back">
                    <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
                </div>
                {{ $route.meta.title }}
            </div>
        </div>
        <activity-tab :tab-name.sync="tabName"></activity-tab>
        <el-alert v-if="(formData && data.isEditAudit == 1 ) || formData && formData.activity.status == 1" show-icon type="warning" title="审核中" :closable="false">
            当前演出处于审核中无法重复提交，除非撤销当前审核。
            <el-button type="text" size="mini" @click="revocation(formData.activity.status)">点击撤销审核</el-button>
        </el-alert>
        <el-form :model="formData" ref="formData" label-position="top" v-loading="dataLoading"
            :disabled="formData && data.isEditAudit == 1 || formData && formData.activity.status == 1">
            <template v-if="formData && reFresh">
                <div v-show="tabName == 1">
                    <activity-base-info :formData.sync="formData.activity"></activity-base-info>
                    <activity-contacts :formData.sync="formData.activity" class="MT10"></activity-contacts>
                    <activity-document :formData.sync="formData.activity" class="MT10"></activity-document>
                </div>
                <div v-show="tabName == 2">
                    <activity-session :formData.sync="formData.sessions" :editType="1" :hallId="formData.activity.hallId" :activityData.sync="formData" ></activity-session>
                </div>
            </template>
        </el-form>
        <div v-if="data" v-show="tabName == 3">
            <through-tickets :throughTickets.sync="data.throughTickets"></through-tickets>
        </div>
		<div v-if="data" v-show="tabName == 4">
		    <refund-tickets :formData.sync="data.refundRuleInfo" :isEdit.sync="isEdit"></refund-tickets>
		</div>
        <div v-if="formMessage" class="formMessage MT10">
            <el-alert type="error" :closable="false" title="提示">
                <div v-html="formMessage"></div>
            </el-alert>
        </div>
        <div class="page-group-box MT10">
            <el-button @click="goBack">返回</el-button>
            <el-button v-if="tabName == 2" @click="$refs['newSession'].show()"
                :disabled="formData && data.isEditAudit == 1">新增场次</el-button>
            <!-- formData.activity.status    0：未提交；1：等待审核；2：预售中；3：活动结束；4：活动取消；5：审核未通过  -->
            <el-button type="primary" @click="onSubmit" :loading="btnLoading"
                :disabled="formData && (formData.activity.status == 1 || formData.activity.status == 3 || formData.activity.status == 4 || data.isEditAudit == 1)">提交审核
            </el-button>
        </div>
        <new-session v-if="formData" ref="newSession" :formData.sync="formData" :activityId="id"></new-session>

        <!-- B类用户自主设置退票申请截止时间 -->
        <el-dialog title="提示" :visible.sync="checkDataVisible" center append-to-body destroy-on-close
            :close-on-click-modal="false" :close-on-press-escape="false" width="700px">
            <div v-if="checkData" class="dialog-content">
                <div class="tips">
                    您修改了以下关键信息：
                    <div class="color-danger">{{ checkData.content }}</div>
                    用户有权申请对应场次退票，因此请提前设置申请退票的截止时间
                    <div class="color-danger">申请退票截止时间通常设置为开演前的某个时间节点（用户只能在该时间以前申请退票），如果还未确定，可以设置为当前时间</div>
                </div>
                <el-form :model="submitData" ref="checkDataForm" label-position="top">
                    <el-table :data="checkData.sessions">
                        <el-table-column prop="sessionName" label="场次">
                            <template slot-scope="scope">
                                <div class="MB20">{{ scope.row.sessionName }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="startTime" label="开演时间">
                            <template slot-scope="scope">
                                <div class="MB20">{{ scope.row.startTime }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="申请退票截止时间" min-width="120">
                            <template slot-scope="scope">
                                <el-form-item :prop="`sessions[${scope.row.index}].canRefundTime`"
                                    :rules="[{ required: true, message: '请填写申请退票截止时间' }]">
                                    <el-date-picker v-model="submitData.sessions[`${scope.row.index}`].canRefundTime"
                                        type="datetime" placeholder="申请退票截止时间" value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM-dd HH:mm" size="mini"
                                        :picker-options="{ disabledDate(time) { return time.getTime() > new Date(scope.row.startTime).getTime() } }"
                                        ></el-date-picker>
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="checkDataVisible = false">暂不提交</el-button>
                <el-button type="primary" @click="submitCanRefundTime()">继续提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import ActivityTab from './components/activity-tab';
import ActivityBaseInfo from './components/activity-baseInfo';
import ActivityContacts from './components/activity-contacts';
import ActivityDocument from './components/activity-document';
import ActivitySession from './components/activity-session';
import NewSession from './components/new-session';
import ThroughTickets from './components/throughTickets.vue';
import RefundTickets from './components/refundTickets.vue';
export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
    },
    components: { ActivityTab, ActivityBaseInfo, ActivityContacts, ActivityDocument, ActivitySession, NewSession, ThroughTickets ,RefundTickets},
    data() {
        return {
            redirect: this.$route.query.redirect || '/manage/activity/list',
            dataLoading: false,
            btnLoading: false,
            formData: null,
            submitData: null,
            data: null,
            reFresh: false,
            formMessage: '',
            tabName: '1',
            checkDataVisible: false,
            checkData: null,
			artistType: 1,
			isEdit:false,
			SourceType:''
			
        }
    },
    created() {
        this.getDataDetail();
    },
    watch: {
        'formData.activity.poster'(v) {
            if (v) {
                this.$refs["formData"].clearValidate('activity.poster');
            }
        }
    },
    methods: {
        //获取详情
        getDataDetail() {
            this.dataLoading = true;
            this.$request({
                url: "/activity/detail",
                params: { activityId: parseInt(this.id) },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        const { activity, sessions, throughTickets,refundRuleInfo } = result;
                        let _formData = {
                            activity: {
                                ...activity,
                                siteId: activity.siteId || '',
                                siteVO: activity.siteVO ? activity.siteVO : { id: '', name: '' },
                                sponsorsIds: activity.sponsors.map(v => v.id) || [],
                                customSite: activity.customSite || '',
                                serviceTemplateId: activity.serviceTemplateId ? activity.serviceTemplateId.split(',') : [],
                                approvalsUserNum: activity.approvalsUserNum || 0,
                                approvals1: '',
                                approvals2: '',
								serviceTemplateList:[]
                            },
                            sessions: sessions,
							refundRuleInfo:refundRuleInfo
                        }

                        if (_formData.activity.approvals) {
                            var approvals = _formData.activity.approvals.split(',')
                            if (approvals.length > 0 && approvals[0]) {
                                _formData.activity.approvals1 = approvals[0]
                            }
                            if (approvals.length > 0 && approvals[1]) {
                                _formData.activity.approvals2 = approvals[1]
                            }
                        }
                        _formData.sessions.map(item => {
                            item.setTime = false
                            item.artistList = item.performers ? [...item.performers] : [];
							item.guestsList = item.guests ? [...item.guests] : [];
							item.guests = item.guests || [];
                            item.ticketPrices = item.ticketPrices || [];
                            if (item.performers && item.performers.length > 0) {
                                item.performers.forEach(items => {
                                    items.datetimerangeUser = [items.startTime, items.endTime];
                                })
                            }
                            item.canRefundTime = item.canRefundTime || "";
                            //item.performersTime = JSON.parse(JSON.stringify(item.performers));
                        })

                        this.formData = _formData;
                        this.data = result;
						this.SourceType = result.refundRuleInfo.refundRuleType                        
						this.reFresh = true;
                    } else {
                        self.$message.error(msg || "获取演出失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取演出失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
		//表单提交
		onSubmit() {
			if( this.SourceType != this.formData.refundRuleInfo.refundRuleType ){
				this.isEdit = true
			}
			
			//退票规则类型 1：未设置 2：演出级别 3：场次级别
			var msg = '请保证退票规则跟演出简介内的描述一致，提交后需人工审核修改通过后才能生效，退票规则不宜频繁多次调整，请谨慎对待!'
			this.$refs["formData"].validate((valid) => {
				if (valid) {
					
					if(  this.formData.refundRuleInfo.refundRuleType == 3 ){
						var refundTimeError = ""			
						this.formData.refundRuleInfo.sessionRefundRules.forEach((sessionItem, sessonIndex) => {
							sessionItem.refundRules.forEach((refundItem, refundIndex) => {
								//console.log(sessionItem)
								//return
								if(refundIndex > 0){
									var prevItem = sessionItem.refundRules[refundIndex-1]
									if(refundTimeError == "" && (Date.parse(refundItem.startTime) - Date.parse(prevItem.endTime) )/1000 >= 2){
										refundTimeError = `<div>场次：${sessionItem.sessionName}，阶段${refundIndex}到阶段${refundIndex+1}之间存在未涵盖的时间段。</div>\
																				            <div class="color-danger">未涵盖的时间段将视为不支持退票，是否继续提交？</div>`;
									}
								}
								
							})
						})
						if(refundTimeError != ""){
							this.$confirm(refundTimeError, '提示', {
								// type: 'success',
								// center: true,
								confirmButtonText: '提交',
								cancelButtonText: '取消',
								closeOnClickModal: false,
								dangerouslyUseHTMLString: true,
							}).then(() => {
								
								console.log(this.isEdit)
								if( this.isEdit ){
									this.$confirm(msg, '提示', {
										confirmButtonText: '提交',
										cancelButtonText: '取消',
										closeOnClickModal: false,
										dangerouslyUseHTMLString: true,
									}).then(() => {
										this.submitForm()
										
									})
								}else{
									this.submitForm()
								}
								
								
							})
						}else{
							if( this.isEdit ){
								this.$confirm(msg, '提示', {									
									confirmButtonText: '提交',
									cancelButtonText: '取消',
									closeOnClickModal: false,
									dangerouslyUseHTMLString: true,
								}).then(() => {
									this.submitForm()
									
								})
							}else{
								this.submitForm()
							}
						}
					}else{
						if( this.isEdit ){
							this.$confirm(msg, '提示', {
								confirmButtonText: '提交',
								cancelButtonText: '取消',
								closeOnClickModal: false,
								dangerouslyUseHTMLString: true,
							}).then(() => {
								this.submitForm()
								
							})
						}else{
							this.submitForm()
						}
					}
					
					
				}
			});
		},
        //表单提交
        submitForm() {
			
			
            var self = this;
            var approvals = ''
            if (this.formData.activity.approvals1 != '' && this.formData.activity.approvals2 != '') {
                approvals = this.formData.activity.approvals1 + "," + this.formData.activity.approvals2
            } else if (this.formData.activity.approvals1 == '') {
                approvals = this.formData.activity.approvals2
            } else if (this.formData.activity.approvals2 == '') {
                approvals = this.formData.activity.approvals1
            } else {
                approvals = ''
            }
            this.formData.activity.approvals = approvals
			
			
			
            try {
                this.submitData = {
                    ...this.formData,
                    activity: {
                        ...this.formData.activity,
                        serviceTemplateId: this.formData.activity.serviceTemplateId.toString()
                    }
                };

                this.submitData.sessions.forEach(item => {
                    var newUser = []
                    item.performers.forEach(v => {
                        newUser.push({
                            id: v.id,
                            name: v.name,
                            startTime: v.datetimerangeUser && v.datetimerangeUser != null && v.datetimerangeUser[0] != undefined ? v.datetimerangeUser[0] : "",
                            endTime: v.datetimerangeUser && v.datetimerangeUser != null && v.datetimerangeUser[1] != undefined ? v.datetimerangeUser[1] : "",
                            startUnix: v.datetimerangeUser && v.datetimerangeUser != null && v.datetimerangeUser[0] != undefined ? new Date(v.datetimerangeUser[0].replace(/-/g, "/")).getTime() : 0,
                            endUnix: v.datetimerangeUser && v.datetimerangeUser != null && v.datetimerangeUser[1] != undefined ? new Date(v.datetimerangeUser[1].replace(/-/g, "/")).getTime() : 0,
                        })
                    })

                    var timeNull = 0
                    for (var i1 = 0; i1 < newUser.length; i1++) {
                        if (newUser[i1].startTime == "" || newUser[i1].endTime == "") {
                            ++timeNull
                        }
                    }
                    if (timeNull > 0 && timeNull != newUser.length) {

                        throw new Error(item.sessionName + "还有" + timeNull + "个艺人出场时间段未配置，请补全后提交");
                        return false;
                    }
                    if (timeNull == 0) {
                        for (i1 = 0; i1 < newUser.length; i1++) {
                            var v1 = newUser[i1]
                            for (var i2 = 0; i2 < newUser.length; i2++) {
                                if (i1 == i2) {
                                    continue;
                                }
                                var v2 = newUser[i2]
                                if (v1.startUnix <= v2.endUnix && v1.endUnix >= v2.startUnix) {

                                    throw new Error(item.sessionName + '的艺人演出时间段和其他艺人重叠，请重新选择');
                                    return false;
                                }
                            }
                        }
                    }

                    //item.performers = newUser;
                })

            } catch (err) {
                return this.$message.error(err.message);
            }
			
            self.$refs["formData"].validate(async (valid, label) => {
                if (valid) {
                    this.formMessage = '';

                    const _newSessions = this.submitData.sessions.filter(v => v.status != -1);

                    const _names = _newSessions.map(item => item.sessionName || item.sessionNamePlaceholder);
                    if (new Set(_names).size !== _names.length) {
                        return this.$message.error('场次名称有重复,请重新输入');
						
                    }

                    let timeFlg = 0;
                    this.submitData.sessions.map((v, i) => {

                        if (this.$utils.compareDate(v.startTime, v.endTime)) {
                            this.formMessage += `<div>场次${i + 1}: 演出开始时间必须小于演出结束时间</div>`;
                        }
                        // if (v.canRefundTime && this.$utils.compareDate2(v.canRefundTime, v.startTime)) {
                        //     this.formMessage += `<div>场次${i + 1}: 申请退票截止时间不能晚于演出开始时间</div>`;
                        // }
                        v.ticketPrices.map(t => {
                            if (new Date(t.startTime.replace(/-/g, "/")).getTime() < new Date().getTime()) {
                                timeFlg += 1;
                            }
                        })

                        v.sessionName = v.sessionName || v.sessionNamePlaceholder
                    })

                    if (this.formMessage && this.formMessage != '') return;
					this.btnLoading = true
                    if (timeFlg > 0) {
                        const flag = await this.$confirm('有票品的开售时间早于当前时间，这会导致演出通过审核后立即开票，是否继续下一步', '提示', {
                            confirmButtonText: '继续下一步',
                            cancelButtonText: '返回修改',
                            showClose: false,
                            closeOnClickModal: false,
                            closeOnPressEscape: false,
                            center: true,
                            type: 'warning',
                        }).catch(() => 'cancel');
						
                        if (flag === 'cancel') {
							this.btnLoading = false
                            return;							
                        }
						
                    }
                    this.editCheck();
					this.btnLoading = false

                }else {
                    let keys = Object.keys(label)
                    const formMessage = label[keys[0]][0].message;
                    this.formMessage = formMessage;
                    return false;//this.$message.error(formMessage);
                }
            });
        },
        //检测变动
        editCheck() {
            this.btnLoading = true;
            this.$request({
                url: "/activity/edit/check",
                data: {
                    ...this.submitData,
                },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        if (result.content && result.sessions) {
							this.btnLoading = false;
                            this.checkData = result;
                            this.checkDataVisible = true;
                            this.checkData.sessions.map(v => {
                                v.index = this.submitData.sessions.findIndex(item => item.id == v.sessionId)
                            })
                        } else {
                            if( this.checkData ){
                                this.submitData.sessions.map(v => {
                                    v.canRefundTime = ""
                                })
                            }
                            
                            this.submitAudit();
                        }
                    } else {
                        return this.$message.error(msg || "操作失败,请重试");
                    }
                })
                .catch(() => {
                    return this.$message.error("操作失败,请重试");
                })
        },
        //退票时间设置 并提交审核
        submitCanRefundTime(){
            this.$refs["checkDataForm"].validate(async (valid) => {
                if (valid) {
                    let submitFlag = true, formMessage = "";
                    this.submitData.sessions.map((v, i) => {
                        if (v.canRefundTime && this.$utils.compareDate2(v.canRefundTime, v.startTime)) {
                            submitFlag = false
                            formMessage = `场次(${v.sessionName}): 申请退票截止时间不能晚于演出开始时间`
                        }
                    })
                    if( !submitFlag ){
                        return this.$message.error(formMessage);
                    }else{
                        this.submitAudit();
                        this.checkDataVisible = false;
                    }
                }
            })
        },
        //提交审核
        submitAudit() {
			
            const _url = "/activity/edit/audit";
            this.btnLoading = true;

            this.$request({
                url: _url,
                data: {
                    ...this.submitData,
                },
                method: "post",
            })
                .then((res) => {
                    const { state, msg } = res.data;
                    if (state == 1) {
					   this.$message.success("操作成功");
                       this.goBack()
                    } else {
                        this.$message.error(msg || "操作失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("操作失败,请重试");
                })
                .finally(() => {
                    this.btnLoading = false;
                });
        },
        //撤销审核
        revocation(status) {
			
			var msg = '',url = '';
			if( status == 1 ){
				msg = '撤销审核后，你可以在演出草稿列表找到该演出，是否撤销?'
				url = '/activity/revocation/onlineAudit'
			}else{
				msg = '撤销审核以后,正在等待审核的改动将会还原,是否撤销?'
				url = '/activity/revocation/audit'
			}
			
            this.$confirm(msg, '撤销审核', {
                type: 'warning',
                center: true,
                closeOnClickModal: false
            }).then(() => {
                this.$request({
                    url: url,
                    params: { activityId: parseInt(this.id) },
                    method: "post",
                })
                    .then((res) => {
                        const { state, msg } = res.data;
                        if (state == 1) {
							if( status == 1 ){
								this.$router.push({
								    path: `/manage/activity/draft`,
								    query: {
								        redirect: this.$router.currentRoute.path,
								    },
								});
							}else{
								this.reFresh = false;
								this.getDataDetail();
							}
                            
                        } else {
                            this.$message.error(msg || "操作失败,请重试");
                        }
                    })
                    .catch(() => {
                        this.$message.error("操作失败,请重试");
                    })
            }).catch(() => { })
        },

        //返回
        goBack() {
            this.$router.push({
                path: this.redirect,
            });
        },
    }

}
</script>

<style lang="scss" scoped>
.el-alert {
    margin-bottom: 15px;
}

.MB20 {
    margin-bottom: 20px;
}</style>