<template>
<div>
    <el-drawer
        title="复制演出"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        append-to-body
        size="auto"
        @close="close"
    >
        <div class="drawer-content" v-loading="dataLoading">
            <el-alert type="warning" title="请先指定一场演出，用于快速复制生成其他演出" :closable="false"></el-alert>
            <el-form :model="formData" ref="formData" class="MT20" label-position="top" size="medium">
                <el-form-item prop="activityId" label="演出ID:" :rules="[{required: true, message: '请输入演出ID'}]">
                    <el-input v-model="formData.activityId" placeholder="请输入演出ID" class="input-with-button">
                        <el-button slot="append" @click="parseActivity">解析</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item prop="title" label="演出名称:" :rules="[{required: true, message: '请解析演出'}]" :show-message="formData.title ? false : true">
                    <div class="text">{{formData.title}}</div>
                </el-form-item>
                <!-- <el-form-item prop="copies" label="复制次数:" :rules="[{required: true, message: '请输入复制次数'}]">
                    <el-input-number style="width:100%" v-model="formData.copies" :precision="0" controls-position="right" :min="1" :max="100" size="medium" placeholder="请输入复制次数"></el-input-number>
                </el-form-item> -->
                <el-form-item v-if="formData.tourName && formData.tourId" label="所属巡演:">
                    <div class="text">{{formData.tourName}}（巡演ID：{{formData.tourId}}）</div>
                </el-form-item>
                <el-form-item v-if="formData.details && formData.details.length > 0" label="复制演出列表">
                    <span slot="label">
                        复制演出列表 
                        <el-button v-if="formData.details.length < 50" type="text" size="mini" icon="el-icon-circle-plus-outline" @click="addActivity">新增演出</el-button>
                    </span>
                    <small>
                        <span class="el-icon-warning"></span>
                        以下信息可以点击每个单元格进行编辑，均为必填项，其余信息会<span class="color-danger">原样</span>复制，如需修改请复制完成后进入演出进行单独修改<span class="color-danger">（比如开票时间）</span>
                    </small>
                    <div class="page-list-box">
                        <el-table
                            :data="formData.details"
                            style="width: 100%"
                            size="small"
                        >
                            <el-table-column prop="siteName" label="场地" min-width="150">
                                <template slot-scope="scope">
                                    <el-form-item :prop="`details[${scope.$index}].siteVO`" :rules="[{required: true, message: '请输入演出场地'}]">
                                        <el-select 
                                            v-model="scope.row.siteVO" 
                                            filterable 
                                            remote
                                            placeholder="请输入演出场地"
                                            :remote-method="searchSite"
                                            :loading="userLoading"
                                            size="mini"
                                            value-key="key"
                                            @focus="activityIndex = scope.$index">
                                            <el-option
                                                v-for="(item,index) in activityList[scope.$index].siteList"
                                                :key="index"
                                                :label="item.name"
                                                :value="item">
                                            </el-option>
                                            <div v-if="!userLoading" slot="empty" class="empty">
                                                如果没有搜索到需要的场地，<br>
                                                可以<el-button type="text" size="mini" @click="$refs.customSite.show()">点击创建</el-button>
                                            </div>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="startTime" label="首场次开演时间" min-width="180">
                                <template slot="header">
                                    首场次开演时间 
                                    <el-popover width="300" content="这里只会修改演出中第一个场次的时间，如果您复制的演出存在多个场次，那么请复制完成后进入演出进行单独修改" placement="top">
                                        <span class="el-icon-warning color-danger" slot="reference"></span>
                                    </el-popover>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item :prop="`details[${scope.$index}].startTime`" :rules="[{required: true, message: '请输入首场次开演时间'}]">
                                        <el-date-picker v-model="scope.row.startTime" type="datetime" placeholder="演出开演时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm" default-time="20:30:00" size="mini"></el-date-picker>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="endTime" label="首场次结束时间" min-width="180">
                                <template slot-scope="scope">
                                    <el-form-item :prop="`details[${scope.$index}].endTime`" :rules="[{required: true, message: '请输入首场次结束时间'}]">
                                        <el-date-picker v-model="scope.row.endTime" type="datetime" placeholder="演出结束时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm" default-time="22:00:59" size="mini"></el-date-picker>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column  v-if="formData.tourName && formData.tourId" label="自动加入巡演" min-width="150" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-form-item >
                                        <el-checkbox v-model="scope.row.isJoinTour" :true-label="1" :false-label="0">{{formData.tourName}}</el-checkbox>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="80">
                                <template slot-scope="scope">
                                    <el-button v-if="scope.$index > 0" type="text" size="mini" @click="delActivity(scope.$index)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" :loading="btnLoading" @click="bindEvent">开始复制</el-button>
        </div>
    </el-drawer>
    <custom-site ref="customSite" @onUpdate="setCustomSite"></custom-site>
</div>
</template>

<script>
import CustomSite from "@/views/manage/activity/edit/components/customSite";
import {fetchUser} from '@/assets/js/commonApi';
const defaultFormData = {
    activityId:'',
    // copies:'',
    title: "",
    details: [],
	copy:1
}
export default {
    components:{CustomSite},
    data(){
        return{
            drawerShow: false,
            formData:{
                ...defaultFormData
            },
            btnLoading: false,
            data: null,
            dataLoading: false,
            userLoading: false,

            activityIndex: 0,
            activityList: [{
                siteList:[]
            }]
        }
    },
    methods:{
        show(){
            this.drawerShow = true
        },
        close(){
            this.formData = { ...defaultFormData };
            this.$refs.formData.resetFields();
            this.drawerShow = false
        },
        //解析
        parseActivity(){
            if (this.formData.activityId) {
                this.dataLoading = true;

                this.$request({
                    url: '/activity/parsingActivityId',
                    params: {activityId:this.formData.activityId},
                    method: "get",
                })
                    .then((res) => {
                        const { state, msg, result } = res.data;
                        if (state == 1 && result) {
                            this.formData = {
                                ...this.formData,
                                ...result
                            }
                        } else {
                            this.$message.error(msg || "操作失败,请重试");
                        }
                    })
                    .catch(() => {
                        this.$message.error("操作失败,请重试");
                    })
                    .finally(() => {
                        this.dataLoading = false;
                    });
            }else{
                return false;
            }  
        },
        //新增演出
        addActivity(){
            this.formData.details.push({
                endTime: "",
                isJoinTour: 1,
                startTime: "",
                siteVO: null
            });
            this.activityList.push({
                siteList:[]
            });
        },
        //搜索场地
        async searchSite(query){
            if(query !== ""){
                this.userLoading = true;
                const res = await fetchUser({
                    queryValue:query,
                    userType:3,
                }).finally(() => {
                    this.userLoading = false;
                });
                
                const { state, result } = res.data;
                
                if (state == 1 && result) {
                    this.activityList[this.activityIndex].siteList = [...result];
                    this.activityList[this.activityIndex].siteList.map(v => v.key = v.id);
                }
            }
        },
        //自定义场地
        setCustomSite(customData){
            const _customData = {
                ...customData,
                key: this.$utils.uuid()
            }
            this.activityList[this.activityIndex].siteList = [{..._customData}];
            this.$nextTick(() => {
                this.$set(this.formData.details,this.activityIndex, {
                    ...this.formData.details[this.activityIndex],
                    siteVO: {..._customData}
                })
            })
        },
        //删除演出
        delActivity(index){
            this.activityList = this.activityList.filter((v,i) => index !== i);
            this.formData.details = this.formData.details.filter((v,i) => index !== i);
        },
        //复制
        bindEvent(){
            this.$refs["formData"].validate(async (valid) => {
                if (valid) {
                    // this.btnLoading = true;
                    let siteFlag = true;
                    this.formData.details.map(item => {
                        const siteId = item.siteVO && item.siteVO.id ? item.siteVO.id : "";
                        const customSite = item.siteVO && !item.siteVO.id ? item.siteVO : null;

                        item.siteId = siteId;
                        item.customSiteObj = customSite && {...customSite} || null;
                        item.customSite = JSON.stringify(item.customSiteObj);
                    })

                    this.formData.details.map(item => {
                        if( item.siteId && (this.formData.details.filter(v => v.siteId === item.siteId) ).length > 1 ){
                            siteFlag = false;
                        }
                        if( item.customSiteObj && (this.formData.details.filter(v => v.customSiteObj && v.customSiteObj.name === item.customSiteObj.name) ).length > 1 ){
                            siteFlag = false;
                        }
                    })
                    

                    if( !siteFlag ){
                        const flag = await this.$confirm('存在相同的场地，是否需要修改', '提示', {
                            type: 'warning',
                            confirmButtonText: '继续复制',
                            cancelButtonText: '返回修改',
                            customClass:'mpop',
                            showClose: false,
                            closeOnClickModal: false,
                            closeOnPressEscape: false,
                        }).catch(() => 'cancel');

                        if (flag === 'cancel') {
                            return;
                        }

                    }
                    
                    
                    this.$request({
                        url: '/activity/copyActivity',
                        data: {
                            ...this.formData,
                        },
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$confirm('您可以在【演出草稿】列表看到新复制的演出', '复制完成', {
                                    type: 'success',
                                    center: true,
                                    confirmButtonText:'去演出草稿列表',
                                    cancelButtonText:'确定',
                                    closeOnClickModal: false
                                }).then(() => {
                                    this.$router.push('/manage/activity/draft');
                                }).catch(() => {
                                    // this.$emit('onUpdate');
                                })

                                this.close();

                            } else {
                                this.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }
            })
            
        }
    }
}
</script>
<style lang="scss" scoped>
.text{
    line-height: normal;
}
.page-list-box{
    margin-top: 0px;
}
.empty{
    padding: 20px;
}
</style>